<template>
  <Teleport to="#cars-slider">
    <div v-if="mockData.length > 0" class="cars-slider">
      <div class="cars-slider__content">
        <div class="cars-slider__dashboard dashboard">
          <Transition name="fade" mode="out-in">
            <h3
              v-if="mockData[currentIndex]"
              class="dashboard__title"
              :key="currentIndex"
            >
              {{
                mockData[currentIndex]?.mark +
                " " +
                mockData[currentIndex]?.title
              }}
            </h3>
          </Transition>
          <ul class="dashboard__list">
            <li
              class="dashboard__item"
              :class="{
                dashboard__item_disabled: !mockData[currentIndex]?.year,
              }"
            >
              <span class="dashboard__item-name">Год выпуска</span>
              <Transition name="fade" mode="out-in">
                <span
                  class="dashboard__item-value"
                  :key="mockData[currentIndex]?.year"
                >
                  {{ mockData[currentIndex]?.year }}
                </span>
              </Transition>
              <div class="dashboard__range">
                <div
                  class="dashboard__range-progress"
                  :style="`--progress: ${animatedProgreses.year}`"
                ></div>
              </div>
            </li>
            <li
              class="dashboard__item"
              :class="{
                dashboard__item_disabled: !mockData[currentIndex]?.volume,
              }"
            >
              <span class="dashboard__item-name">Объем двигателя</span>
              <Transition name="fade" mode="out-in">
                <span
                  class="dashboard__item-value"
                  :key="mockData[currentIndex]?.volume"
                >
                  {{
                    mockData[currentIndex]?.volume
                      ? mockData[currentIndex]?.volume
                      : ""
                  }}
                </span>
              </Transition>
              <div class="dashboard__range">
                <div
                  class="dashboard__range-progress"
                  :style="`--progress: ${animatedProgreses.volume}`"
                ></div>
              </div>
            </li>
            <li
              class="dashboard__item"
              :class="{
                dashboard__item_disabled: !mockData[currentIndex]?.power,
              }"
            >
              <span class="dashboard__item-name">Мощность</span>
              <Transition name="fade" mode="out-in">
                <span
                  class="dashboard__item-value"
                  :key="mockData[currentIndex]?.power"
                >
                  {{
                    mockData[currentIndex]?.power
                      ? mockData[currentIndex]?.power + " л.с."
                      : ""
                  }}
                </span>
              </Transition>
              <div class="dashboard__range">
                <div
                  class="dashboard__range-progress"
                  :style="`--progress: ${animatedProgreses.power}`"
                ></div>
              </div>
            </li>
            <li class="dashboard__item">
              <span class="dashboard__item-name">Топливо</span>
              <span class="dashboard__item-value">
                <svg
                  :class="
                    mockData[currentIndex]?.fuel.includes(enginesTypes.fuel) &&
                    'active'
                  "
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.375 13.071C11.375 13.8886 10.7606 14.5 10 14.5C9.23943 14.5 8.625 13.8886 8.625 13.071C8.625 12.9038 8.70822 12.577 8.88447 12.12C9.0524 11.6847 9.27928 11.1937 9.51044 10.7275C9.68118 10.3833 9.85221 10.0566 10 9.78162C10.1478 10.0566 10.3188 10.3833 10.4896 10.7275C10.7207 11.1937 10.9476 11.6847 11.1155 12.12C11.2918 12.577 11.375 12.9038 11.375 13.071Z"
                    fill="currentColor"
                    stroke="currentColor"
                  />
                  <path
                    d="M9.2001 1.25H5.2251V4.43333H9.2001V1.25Z"
                    stroke="currentColor"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M16.3666 3.63359V17.1586C16.3666 17.5807 16.1989 17.9856 15.9004 18.2841C15.6019 18.5826 15.1971 18.7503 14.775 18.7503H5.22497C5.01595 18.7503 4.80897 18.7091 4.61586 18.6291C4.42275 18.5491 4.24729 18.4319 4.09949 18.2841C3.80099 17.9856 3.6333 17.5807 3.6333 17.1586V6.02526C3.6333 5.60312 3.80099 5.19828 4.09949 4.89978C4.39798 4.60129 4.80283 4.43359 5.22497 4.43359H10.8333L11.9583 2.76693C12.1038 2.54889 12.3008 2.37015 12.532 2.24658C12.7631 2.12301 13.0212 2.05844 13.2833 2.05859H14.8166C15.228 2.07141 15.6185 2.24222 15.9072 2.53553C16.1959 2.82885 16.3604 3.22211 16.3666 3.63359Z"
                    stroke="currentColor"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M10.8335 4.43359C11.3456 4.94103 12.0375 5.22557 12.7585 5.22526H16.4085"
                    stroke="currentColor"
                    stroke-miterlimit="10"
                  />
                </svg>
                <svg
                  :class="
                    mockData[currentIndex]?.fuel.includes(
                      enginesTypes.electric
                    ) && 'active'
                  "
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_310_10725)">
                    <path
                      d="M6.80846 9.98359H6.2168L6.80846 9.18359V9.98359ZM6.80846 9.98359L7.40872 9.9834L6.80872 10.7834L6.80846 9.98359ZM14.7834 0.416992V3.60866M17.9747 0.416992V3.60866M16.3752 6.79199V14.767C16.3752 15.4013 16.1232 16.0096 15.6747 16.4582C15.2262 16.9067 14.6178 17.1587 13.9835 17.1587H12.3918M5.41679 1.21667H8.20846C8.31891 1.21667 8.40846 1.30621 8.40846 1.41667V2.60833C8.40846 2.71879 8.498 2.80833 8.60845 2.80833H10.6001C10.7106 2.80833 10.8001 2.89788 10.8001 3.00833V15.3583C10.8001 15.4688 10.7106 15.5583 10.6001 15.5583H3.02512C2.91467 15.5583 2.82512 15.4688 2.82512 15.3583V3.00833C2.82512 2.89788 2.91466 2.80833 3.02512 2.80833H5.01679C5.12725 2.80833 5.21679 2.71879 5.21679 2.60833V1.41667C5.21679 1.30621 5.30633 1.21667 5.41679 1.21667ZM1.4334 15.5586H12.1917C12.3022 15.5586 12.3917 15.6481 12.3917 15.7586V18.5503C12.3917 18.6607 12.3022 18.7503 12.1917 18.7503H1.4334C1.32294 18.7503 1.2334 18.6607 1.2334 18.5503V15.7586C1.2334 15.6481 1.32294 15.5586 1.4334 15.5586ZM13.9834 5.20033V3.60866H18.7667V5.20033C18.7667 5.62246 18.599 6.02731 18.3005 6.3258C18.002 6.6243 17.5972 6.79199 17.1751 6.79199H15.5751C15.1529 6.79199 14.7481 6.6243 14.4496 6.3258C14.1511 6.02731 13.9834 5.62246 13.9834 5.20033Z"
                      stroke="currentColor"
                      stroke-miterlimit="10"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_310_10725">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </span>
            </li>
          </ul>
        </div>
        <BottomZoomSlider
          :slides="mockData"
          textProp="model"
          class="cars-slider__slider"
          @slide-change="
            (index) => {
              currentIndex = index;
            }
          "
        />
      </div>
    </div>
  </Teleport>
</template>

<script setup>
import BottomZoomSlider from "@/components/BottomZoomSlider.vue";
import Range from "@/components/Range.vue";

import anime from "animejs";
import { computed, ref, watch } from "vue";

const props = defineProps({
  cars: {
    type: String,
  },
});

const enginesTypes = {
  fuel: "Бензин",
  electric: "Электричество",
};

const container = document.getElementById("cars-slider");
const { cars } = container.dataset;

const carsArr = props.cars ? props.cars : cars ? JSON.parse(cars) : [];
const mockData = ref(carsArr);

const currentIndex = ref(0);
const dasboardLimits = ref({
  year: [2000, 2024],
  volume: [0, 2],
  power: [0, 1305],
});
const dasboardProgreses = computed(() => {
  const progress = {};
  for (let key in dasboardLimits.value) {
    if (
      mockData.value.length > 0 &&
      mockData.value[currentIndex.value] &&
      mockData.value[currentIndex.value][key]
    ) {
      const progressValue =
        mockData.value[currentIndex.value][key].match(/\d*\.?\d*/)[0] -
        dasboardLimits.value[key][0];
      const range = dasboardLimits.value[key][1] - dasboardLimits.value[key][0];
      progress[key] = progressValue / range;
    } else {
      progress[key] = false;
    }
  }
  return progress;
});
const animatedProgreses = ref({});

watch(
  () => props.cars,
  (value) => {
    mockData.value = value;
  },
  { deep: true }
);
watch(dasboardProgreses, animationUpdate, { immediate: true });

function animationUpdate(progress) {
  for (let key in dasboardLimits.value) {
    if (!animatedProgreses.value[key]) animatedProgreses.value[key] = 0;

    anime({
      targets: animatedProgreses.value,
      keyframes: [{ [key]: 0 }, { [key]: progress[key] ? progress[key] : 0 }],
      easing: "easeInOutCubic",
      duration: 800,
    });
  }
}
</script>

<style lang="scss">
.cars-slider {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    position: relative;
    height: 480px;
    padding: 220px 0 30px;
    margin: auto;
    color: white;
    perspective: 900px;
    max-width: 1440px;
  }

  &__dashboard {
    position: absolute;
    transform-origin: top center;
    transform: perspective(25cm) rotateY(20deg);
    bottom: calc(24% + 161px);
    left: 16%;
  }

  &__image-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: calc(323 / 645 * 100%);
  }

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__image-shadow {
    position: absolute;
    bottom: 200px;
    left: 50%;
    transform: translate(-50%, 0) rotateZ(354deg);
    width: 87%;
    height: 31%;
    border-radius: 50%;
    box-shadow: 0 200px 59px rgba(0, 0, 0, 1);
  }
}

.dashboard {
  &__title {
    margin-bottom: 30px;
    font-size: 26px;
    font-weight: 700;
  }
  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    margin: 0;
    padding: 40px 34px;
    width: 278px;
    background-color: #e3e3e34d;
    border: 1px solid rgba(249, 253, 255, 0.15);
    border-radius: 5px;
    backdrop-filter: blur(8px);
    list-style-type: none;
  }

  &__item {
    display: grid;
    grid-template-columns: 1fr max-content;
    justify-content: space-between;
    row-gap: 14px;

    &_disabled {
      .dashboard__range {
        background-color: #b5b5b5;
      }
    }
  }

  &__item-value {
    width: 60px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;

    > svg {
      transition: ease 0.5s;
      opacity: 0.3;

      &.active {
        opacity: 1;
      }
    }
  }

  &__range {
    grid-row: 2 / 3;
    grid-column: 1 / 3;
    position: relative;
    height: 5px;
    width: 100%;
    background-color: rgba(249, 253, 255, 1);
    transition: all 300ms;
    clip-path: polygon(5px 0, 100% 0, calc(100% - 5px) 100%, 0 100%);
  }

  &__range-progress {
    --progress: 1;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: calc(var(--progress) * 100%);
    background-color: rgba(0, 178, 255, 1);
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
