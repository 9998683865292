<template>
  <div class="bottom-zoom-slider">
    <swiper-container
      class="bottom-zoom-slider__images image-slider"
      ref="imageSlider"
      :init="false"
    >
      <template v-for="(slide, index) in carsList" :key="slide">
        <swiper-slide class="image-slider__slide">
          <div class="image-slider__slide-wrapper">
            <div class="cars-slider__image-wrapper">
              <a :href="slide.link">
                <div class="cars-slider__image-shadow"></div>
                <img
                  class="cars-slider__image"
                  :src="slide.img"
                  :alt="slide.title"
                />
              </a>
            </div>
          </div>
        </swiper-slide>
      </template>
    </swiper-container>
    <div class="bottom-zoom-slider__other-items other-items">
      <div class="other-items__pivot">
        <Transition name="fade" mode="out-in">
          <span
            v-if="
              imageSliderOptions.loop ||
              (!imageSliderOptions.loop && currentSlide - 1 >= 0)
            "
            class="other-items__item other-items__item_prev"
            :key="currentSlide"
          >
            {{
              currentSlide - 1 >= 0
                ? carsList[currentSlide - 1]?.mark +
                  " " +
                  carsList[currentSlide - 1]?.title
                : carsList[carsList.length - 1]?.mark +
                  " " +
                  carsList[carsList.length - 1]?.title
            }}
          </span>
        </Transition>
      </div>
      <div class="other-items__pivot">
        <Transition name="fade" mode="out-in">
          <span
            v-if="
              imageSliderOptions.loop ||
              (!imageSliderOptions.loop && currentSlide + 1 < slides.length)
            "
            class="other-items__item other-items__item_next"
            :key="currentSlide"
          >
            {{
              currentSlide + 1 < carsList.length
                ? carsList[currentSlide + 1]?.mark +
                  " " +
                  carsList[currentSlide + 1]?.title
                : carsList[0]?.mark + " " + carsList[0]?.title
            }}
          </span>
        </Transition>
      </div>
    </div>
    <div class="bottom-zoom-slider__navigation navigation">
      <div
        v-show="slides.length > 1"
        class="navigation__button-wrapper navigation__button-wrapper_prev"
      >
        <button ref="prevButton" class="navigation__button" type="button">
          ПРЕД.
        </button>
        <div class="navigation__button-head"></div>
        <div class="navigation__button-tail"></div>
      </div>
      <swiper-container
        class="bottom-zoom-slider__text text-slider"
        ref="textSlider"
        :init="false"
      >
        <template v-for="(slide, index) in carsList" :key="slide">
          <swiper-slide class="text-slider__slide">
            {{ slide?.mark + " " + slide?.title }}
          </swiper-slide>
        </template>
      </swiper-container>
      <div
        v-show="slides.length > 1"
        class="navigation__button-wrapper navigation__button-wrapper_next"
      >
        <button ref="nextButton" class="navigation__button" type="button">
          СЛЕД.
        </button>
        <div class="navigation__button-head"></div>
        <div class="navigation__button-tail"></div>
      </div>
    </div>
    <a class="link-to-car" :href="carsList[currentSlide]?.link || '#'">
      Выбрать
    </a>

    <svg class="svg">
      <clipPath id="button-head" clipPathUnits="objectBoundingBox">
        <path
          d="M0.565,0.032 C0.534,0,0.482,0,0.451,0.032 L0.032,0.451 C0,0.482,0,0.534,0.032,0.565 L0.451,0.984 C0.482,1,0.534,1,0.565,0.984 L0.984,0.565 C1,0.534,1,0.482,0.984,0.451 L0.565,0.032"
        ></path>
      </clipPath>
    </svg>
    <svg class="svg">
      <clipPath id="button-tail" clipPathUnits="objectBoundingBox">
        <path
          d="M0,0 H0.356 C0.402,0,0.446,0.014,0.476,0.038 L0.966,0.438 C1,0.474,1,0.526,0.966,0.562 L0.476,0.962 C0.446,0.986,0.402,1,0.356,1 H0 L0.388,0.549 C0.414,0.519,0.414,0.481,0.388,0.451 L0,0"
        ></path>
      </clipPath>
    </svg>
    <svg class="svg">
      <clipPath id="button-body" clipPathUnits="objectBoundingBox">
        <path
          d="M0.133,0.038 C0.14,0.014,0.151,0,0.163,0 H0.843 C0.854,0,0.865,0.014,0.872,0.038 L0.994,0.438 C1,0.474,1,0.526,0.994,0.562 L0.872,0.962 C0.865,0.986,0.854,1,0.843,1 H0.163 C0.151,1,0.14,0.986,0.133,0.962 L0.011,0.562 C0,0.526,0,0.474,0.011,0.438 L0.133,0.038"
        ></path>
      </clipPath>
    </svg>
  </div>
</template>

<script setup>
import { ref, onMounted, nextTick, watch } from "vue";
const props = defineProps({
  slides: {
    type: Array,
    default: [{ text: "", image: "" }],
  },
  textProp: {
    default: "text",
  },
  imageProp: {
    default: "img",
  },
});

const carsList = ref([]);

const emit = defineEmits(["slideChange"]);
const currentSlide = ref(0);
const height = ref(0);
const imageSlider = ref(null);
const textSlider = ref(null);
const prevButton = ref(null);
const nextButton = ref(null);
const imageSliderOptions = ref({
  slidesPerView: 1,
  loop: props.slides.length > 3,
  loopAdditionalSlides: 1,
  centeredSlides: true,
  effect: "creative",
  creativeEffect: {
    limitProgress: 1,
    prev: {
      translate: ["-25%", "140%", 0],
      scale: 0.5,
      opacity: 0.6,
    },
    next: {
      translate: ["25%", "140%", 0],
      scale: 0.5,
      opacity: 0.6,
    },
  },
  breakpoints: {
    480: {
      creativeEffect: {
        limitProgress: 1,
        prev: {
          translate: ["-75%", "50%", 0],
          scale: 0.4,
          opacity: 0.6,
        },
        next: {
          translate: ["75%", "50%", 0],
          scale: 0.4,
          opacity: 0.6,
        },
      },
    },
  },
  navigation: {},
  on: {
    init(swiper) {
      currentSlide.value = swiper.realIndex;
      emit("slideChange", currentSlide.value);
    },
    realIndexChange(swiper) {
      if (swiper.realIndex < carsList.value.length) {
        currentSlide.value = swiper.realIndex;
        emit("slideChange", currentSlide.value);
      }
    },
    resize(swiper) {
      height.value = swiper.height;
    },
  },
});
const imageSliderStyles = ref({
  injectStyles: [
    `
       .swiper-creative {
          overflow: visible;
      }
      `,
  ],
});
const textSliderOptions = ref({
  slidesPerView: 1,
  loop: props.slides.length > 3,
  loopAdditionalSlides: 1,
  centeredSlides: true,
});

function initSliders() {
  const navigation = { prevEl: prevButton.value, nextEl: nextButton.value };
  imageSliderOptions.value.navigation = navigation;
  imageSlider.value.initialized = textSlider.value.initialized = false;
  Object.assign(imageSlider.value, imageSliderOptions.value);
  Object.assign(imageSlider.value, imageSliderStyles.value);
  Object.assign(textSlider.value, textSliderOptions.value);
  imageSlider.value.initialize();
  textSlider.value.initialize();
  imageSlider.value.swiper.controller.control = textSlider.value.swiper;
  textSlider.value.swiper.controller.control = imageSlider.value.swiper;
}

watch(
  () => props.slides,
  async (value) => {
    if (
      imageSlider.value?.swiper &&
      textSlider.value?.swiper &&
      props.slides.length <= 3
    ) {
      textSlider.value.loop =
        imageSlider.value.loop =
        imageSliderOptions.value.loop =
          false;
    } else if (imageSlider.value?.swiper && textSlider.value?.swiper) {
      textSlider.value.loop =
        imageSlider.value.loop =
        imageSliderOptions.value.loop =
          true;
    }
    carsList.value = [];
    await nextTick();
    carsList.value = value;
    await nextTick();
    if (imageSlider.value.swiper && textSlider.value.swiper) {
      imageSlider.value.swiper.destroy(false, false);
      textSlider.value.swiper.destroy(false, false);

      imageSlider.value.initialized = textSlider.value.initialized = false;
      imageSlider.value.initialize();
      textSlider.value.initialize();
      imageSlider.value.swiper.controller.control = textSlider.value.swiper;
      textSlider.value.swiper.controller.control = imageSlider.value.swiper;
    }
  },
  { immediate: true }
);

onMounted(async () => {
  await nextTick();
  if (imageSlider.value && textSlider.value) {
    initSliders();
  }
});
</script>

<style lang="scss" scoped>
.link-to-car {
  background: #00b2ff;
  position: absolute;
  z-index: 1;
  cursor: pointer;
  bottom: 0;
  width: 290px;
  transform: translate(-50%);
  left: 50%;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;

  clip-path: polygon(
    6.129% 3.753%,
    6.129% 3.753%,
    6.234% 3.072%,
    6.348% 2.452%,
    6.471% 1.896%,
    6.601% 1.407%,
    6.738% 0.987%,
    6.881% 0.638%,
    7.029% 0.362%,
    7.182% 0.163%,
    7.338% 0.041%,
    7.497% 0%,
    93.028% 0%,
    93.028% 0%,
    93.187% 0.041%,
    93.343% 0.163%,
    93.496% 0.362%,
    93.644% 0.638%,
    93.787% 0.987%,
    93.924% 1.407%,
    94.054% 1.896%,
    94.177% 2.452%,
    94.291% 3.072%,
    94.396% 3.753%,
    100% 43.753%,
    100% 43.753%,
    100.138% 44.892%,
    100.246% 46.104%,
    100.322% 47.372%,
    100.368% 48.677%,
    100.384% 50%,
    100.368% 51.323%,
    100.322% 52.628%,
    100.246% 53.896%,
    100.138% 55.108%,
    100% 56.247%,
    94.396% 96.247%,
    94.396% 96.247%,
    94.291% 96.929%,
    94.177% 97.548%,
    94.054% 98.104%,
    93.924% 98.593%,
    93.787% 99.013%,
    93.644% 99.362%,
    93.496% 99.638%,
    93.343% 99.837%,
    93.187% 99.959%,
    93.028% 100%,
    7.497% 100%,
    7.497% 100%,
    7.338% 99.959%,
    7.182% 99.837%,
    7.029% 99.638%,
    6.881% 99.362%,
    6.738% 99.013%,
    6.601% 98.593%,
    6.471% 98.104%,
    6.348% 97.548%,
    6.234% 96.929%,
    6.129% 96.247%,
    0.525% 56.247%,
    0.525% 56.247%,
    0.387% 55.108%,
    0.279% 53.896%,
    0.203% 52.628%,
    0.157% 51.323%,
    0.141% 50%,
    0.157% 48.677%,
    0.203% 47.372%,
    0.279% 46.104%,
    0.387% 44.892%,
    0.525% 43.753%,
    6.129% 3.753%
  );

  &:hover {
    background: #de12ff;
    color: #f9fdff;
  }
}
.bottom-zoom-slider {
  position: relative;
  width: 100%;

  &__images {
    width: 50%;
  }

  &__navigation {
    position: absolute;
    bottom: 15%;
    left: 50%;
    z-index: 1;
    width: 55%;
    transform: translate(-50%, 0);
  }

  &__text {
    width: 100%;
  }
}

.image-slider {
  padding-bottom: calc(v-bind(height) * 0.2px);
  &__slide {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
    height: max-content;
  }

  &__slide-wrapper {
    width: 100%;
    opacity: 0;
    transition: all 500ms;

    a {
      position: absolute;
      pointer-events: none;
      width: 100%;
      height: 100%;
    }
  }

  .swiper-slide {
    &-active  {
      a {
        pointer-events: auto;
      }
    }
    &-visible {
      .image-slider__slide-wrapper {
        opacity: 1;
      }
    }
    &-prev {
      .image-slider__slide-wrapper {
        opacity: 1;
      }
    }
    &-next {
      .image-slider__slide-wrapper {
        opacity: 1;
      }
    }
  }
}

.navigation {
  display: grid;
  grid-template-columns: 1fr 340px 1fr;
  grid-template-rows: 1fr;
  justify-items: center;
  height: 50px;

  &:has(.swiper-button-lock) {
    grid-template-columns: 1fr;
  }

  &__button-wrapper {
    position: relative;
    width: 100%;
    pointer-events: none;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 25px;
      transform: translate(0, -50%);
      width: 20%;
      height: 5px;
      background-color: rgba(0, 178, 255, 1);
      transition: all 300ms;
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 4px;
      transform: translate(0, -50%);
      width: 20%;
      height: 5px;
      background-color: rgba(0, 178, 255, 1);
      transition: all 300ms;
    }

    &:hover {
      .navigation {
        &__button {
          background-color: rgba(222, 18, 255, 1);
        }
        &__button-head {
          background-color: rgba(222, 18, 255, 1);
        }
        &__button-tail {
          background-color: rgba(222, 18, 255, 1);
        }
      }
      &::before {
        background-color: rgba(222, 18, 255, 1);
      }

      &::after {
        background-color: rgba(222, 18, 255, 1);
      }
    }

    &_prev {
      .navigation {
        &__button {
          left: auto;
          right: 54%;
          transform: translate(50%, 0);
        }
        &__button-head {
          left: 0;
          right: auto;
        }
        &__button-tail {
          right: 0;
          left: auto;
          transform: translate(0, -50%) rotate(180deg);
        }
      }
      &::before {
        left: auto;
        right: 25px;
      }
      &::after {
        right: auto;
        left: 4px;
      }
    }
  }

  &__button {
    position: absolute;
    top: 0;
    left: 54%;
    transform: translate(-50%, 0);
    width: 140px;
    height: 100%;
    font-size: 18px;
    font-weight: 700;
    color: rgba(249, 253, 255, 1);
    clip-path: url(#button-body);
    background-color: rgba(0, 178, 255, 1);
    transition: all 300ms;
    border: none;
    pointer-events: all;
  }

  &__button-head {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    width: 10px;
    height: 10px;
    clip-path: url(#button-head);
    background-color: rgba(0, 178, 255, 1);
    transition: all 300ms;
  }

  &__button-tail {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    width: 34px;
    height: 100%;
    clip-path: url(#button-tail);
    background-color: rgba(0, 178, 255, 1);
    transition: all 300ms;
  }
}

.text-slider {
  &__slide {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 30px;
    font-size: 26px;
    font-weight: 700;
  }
}
.other-items {
  position: absolute;
  bottom: 40%;
  left: 50%;
  transform: translate(-49%, 0);
  display: flex;
  justify-content: space-between;
  width: 75%;

  &__pivot {
    width: 0;
    height: 0;
    position: relative;
  }

  &__item {
    position: absolute;
    width: max-content;
    top: 0;
    transform: translate(-50%, 0);
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
}
.svg {
  position: absolute;
  width: 0;
  height: 0;
}

@media screen and (max-width: 768px) {
  .swiper-slide-active {
    .cars-slider__image {
      transform: scale(1.1);
    }
  }
}
</style>
